import React from "react"
import Slider from "react-slick"

import Layout from "../components/layout"
import SEO from "../components/seo"

import default_aibot from "../images/default-aibot.png"

const settings = {
  customPaging: function(i) {
    return <span className="slick-number has-text-weight-bold">{i + 1}</span>
  },
  dots: true,
  dotsClass: "slick-dots slick-thumb",
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const WorkPage = () => (
  <Layout>
    <SEO title="Aibots implementados" />
    <section className="section section-aibots-implementados">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-four-fifths">
            <h1 className="title has-text-centered has-text-primary is-size-1">
              AIBOTS IMPLEMENTADOS
            </h1>
          </div>
        </div>
        <Slider {...settings}>
          <div className="container">
            <div className="columns is-multiline">
              <div className="column has-text-centered is-one-fifth is-offset-1">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 1
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 2
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 3
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 4
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth is-offset-1">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 5
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 6
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 7
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 8
                </span>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns is-multiline">
              <div className="column has-text-centered is-one-fifth is-offset-1">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 1
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 2
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 3
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 4
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth is-offset-1">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 5
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 6
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 7
                </span>
              </div>
              <div className="column has-text-centered is-one-fifth">
                <img className="client-icon" src={default_aibot} />
                <span className="is-size-5 has-text-weight-medium has-text-primary is-uppercase is-primary">
                  Cliente 8
                </span>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  </Layout>
)

export default WorkPage
